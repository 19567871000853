import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import echarts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/index.scss'
import axios from 'axios'
import request from '@/api/request'

Vue.config.productionTip = false

// mock引人
if(process.env.NODE_ENV !== 'production') {
  require('./mock/mock_api')
}
Vue.prototype.$axios = axios
Vue.prototype.request = request
Vue.prototype.$echarts = echarts
Vue.prototype.$EventBus = new Vue()
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
