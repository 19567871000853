<script>
// 把store全部映射出来
import { mapMutations, mapState } from 'vuex'
export default {
  computed: {
    // ------------------------------state-----------------------
    ...mapState({
      // 全局使用性的数据
      deviceRatio: state => state.commonStore.deviceRatio
    })
  },
  methods: {
    // -----------------------mutations---------------------------
    // 全局使用性的数据
    ...mapMutations('commonStore', [
      'setDeviceRatio'
    ])
  }
}
</script>
