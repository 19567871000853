<script>
// 监控屏幕缩放及相关操作
import storeMixin from '@/components/Mixins/storeMixin'
export default {
  mixins: [storeMixin],
  data() {
    return {
      screenRatio: window.devicePixelRatio || (window.screen.deviceXDPI / window.screen.logicalXDPI)
    }
  },
  watch: {
    screenRatio(val) {
      this.screenRatio = val
      this.setDeviceRatio(val)
      const dropObj = document.querySelectorAll('.ivu-affix')[0]
      const containObj = document.querySelectorAll('.dashboard-container')[0] || document.querySelectorAll('.layout-container')[0]
      const marginLeft = 1 * window.getComputedStyle(containObj, null).marginLeft.replace('px', '')
      if(dropObj) {
        // 改变下钻left值
        dropObj.style.left = `${marginLeft + 88}px`
      }

      this.checkWebStatus()
    }
  },
  methods: {
    // 监测屏幕倍数
    checkDevicePx() {
      const that = this
      window.onresize = () => {
        return (() => {
          that.screenRatio = window.devicePixelRatio || (window.screen.deviceXDPI / window.screen.logicalXDPI)
          that.setDeviceRatio(that.screenRatio)
        })()
      }
    },
    // 网站状态检查
    checkWebStatus: _.debounce(function() {
      // 若像素比不为1且少于2（兼容mac,mac是2，这里先只处理windows）, 则提示用户调回100%
      if(this.screenRatio < 2 &&
        (this.screenRatio > 1.15 || this.screenRatio < 0.85)
      ) {
        // 缩放状态检查
        this.$Notice.info({
          title: '您的浏览器处于缩放状态',
          desc: '为了更佳的系统操作体验，请按下键盘的"ctrl + 数字键0"的组合键恢复默认展示状态。',
          duration: 3
        })
      }
    }, 500)
  }
}
</script>
