// 全局的store存储
const state = {
  // 屏幕缩放比例
  deviceRatio: 1
}

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  // 设置屏幕倍数信息
  setDeviceRatio(state, info) {
    state.deviceRatio = info
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
