<template>
  <div class="navigation">
    <!-- 导航栏 -->
    <div class="nav_cantainer">
      <div class="logo_content">
        <img
          style="cursor: pointer"
          @click="clickNav({ name: '首页', path: 'home' })"
          src="@/assets/img/home_img_logo.png"
          alt=""
        />
        <div class="slogan">
          铸牢中华民族共同体意识，促进各民族广泛交往交流交融
        </div>
      </div>
      <div class="list_content">
        <div
          :class="['list', activeNav === item.name ? 'active' : '']"
          v-for="(item, index) in list"
          :key="index"
          @click="clickNav(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="manage"></div>
    </div>
    <!-- 轮播图 -->
    <div class="banner">
      <el-carousel>
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <img
            class="b_img"
            @click="goDetail(item.documentId)"
            :src="item.img"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
      <div class="search_content">
        <el-input @change="goSearch" size="small" v-model="state">
          <i
            class="el-icon-search el-input__icon"
            @click="goSearch"
            slot="suffix"
          ></i>
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navigation',
  data() {
    return {
      activeNav: '首页',
      state: '',
      clientWidth: 1200,
      list: [
        { name: '首页', path: 'home' },
        { name: '四川民族概况', path: 'nation' },
        // { name: '特色村寨数字化平台', path: 'village' },
        { name: '民族特色博览', path: 'village' },
        // { name: '资讯速递', path: 'news' },
        { name: '同运动•一家亲', path: 'together' },
        { name: '云上民族村寨', path: 'emvdps' }
      ],
      bannerList: []
      // bannerList: [
      //   { src: require('@/assets/img/1.png') },
      //   { src: require('@/assets/img/2.jpg') },
      //   { src: require('@/assets/img/3.jpg') }
      // ]
    };
  },
  methods: {
    // 跳转至详情页
    goDetail(val) {
      if (val !== 0) {
        this.$router.push({ path: '/detail', query: { id: val } });
      }
    },
    // 获取获取轮播图列表
    getBannerList() {
      this.request.get('getBannerList').then((res) => {
        console.log('66--->', res);
        this.bannerList = res.records;
      });
    },
    goSearch() {
      console.log(`搜索：${this.state}`);
      this.$router.push({ path: '/search', query: { keyWord: this.state } });
    },
    clickNav(item) {
      if (item.path === 'news') {
        this.activeNav = item.name;
        this.$router.push({ path: '/feature', query: { categoryId: 10 } });
      } else if (item.path === 'together') {
        this.activeNav = item.name;
        this.$router.push({ path: '/feature', query: { categoryId: 43 } });
      } else if (item.path === 'village') {
        this.$router.push({ path: '/home' });
        setTimeout(() => {
          this.$EventBus.$emit('scrollToAnchor', '.feature_title');
        }, 500);
      } else if (item.path === 'emvdps') {
        this.$router.push({ path: '/home' });
        setTimeout(() => {
          this.$EventBus.$emit('scrollToAnchor', '.map_content');
        }, 500);
      } else {
        this.activeNav = item.name;
        this.$router.push({ path: `/${item.path}` });
      }
    }
  },
  mounted() {
    this.clientWidth = document.body.scrollWidth;
    this.getBannerList();
  }
};
</script>

<style lang="scss">
.navigation {
  position: relative;
  box-shadow: 0 0.02rem 0.1rem rgba(0, 0, 0, 0.12);
  align-items: center;
  .nav_cantainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 10;
    top: 1rem;
    height: 1.5rem;
    border-radius: 0.06rem;
    padding: 0 0.3rem;
    .human_box {
      position: absolute;
      right: 0.5rem;
      top: -0.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        height: 0.48rem;
        width: 0.48rem;
      }
      span {
        margin-left: 0.1rem;
        font-size: 0.4rem;
        font-family: 'MicrosoftYaHei';
        text-align: left;
        color: #ffffff;
      }
    }
    .logo_content {
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      img {
        width: 8rem;
        height: 95%;
      }
      .slogan {
        position: absolute;
        color: #fff;
        font-size: 0.45rem;
        white-space: nowrap;
        bottom: -0.3rem;
        left: 2.05rem;
        text-shadow: 0 0px 5px rgba(0, 0, 0, 1);
        font-family: '华文隶书', 'MicrosoftYaHei';
      }
    }
    .list_content {
      margin-left: 0.3rem;
      display: flex;
      white-space: nowrap;
      .list {
        font-size: 0.36rem;
        font-weight: normal;
        cursor: pointer;
        transition: all linear 150ms;
        margin: 0 0.2rem;
        padding: 0.08rem 0;
        color: #fff;
        border-bottom: 0.03rem solid transparent;
        &.active,
        &:hover {
          border-bottom: 0.1rem solid #36b374;
          font-weight: bold;
        }
      }
    }
  }
  .banner {
    position: relative;
    width: 100%;
    height: 15rem;
    .el-carousel,
    .el-carousel__container {
      height: 100% !important;
      img {
        width: 100%;
        min-height: 100%;
      }
    }
    .el-carousel__item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .search_content {
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      .el-input__inner {
        width: 8rem;
        height: 0.8rem;
        border-radius: 0.5rem;
        border: 0;
        background: rgba(255, 255, 255, 0.7);
        transition: all linear 150ms;
        padding: 0 0.4rem;
        &:focus {
          // border-color: #fff;
          width: 8.5rem;
          background: rgba(255, 255, 255, 0.8);
          // background: #ffffff;
          color: #333333;
          font-family: 'MicrosoftYaHei';
        }
      }
      .el-input__suffix {
        right: 0.15rem;
      }
      .el-icon-search {
        color: #36b374;
        font-size: 0.4rem;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .navigation .nav_cantainer {
    .logo_content {
      width: 25%;
      img {
        width: 95%;
        height: auto;
      }
    }
    .list_content .list {
      font-size: 1vw;
      margin: 0 0.1rem;
    }
  }
}
</style>
