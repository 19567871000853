import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    meta: { title: '首页' },
    component: () => import('../views/home')
  },
  {
    path: '/feature',
    name: 'feature',
    meta: { title: '特色' },
    component: () => import('../views/feature')
  },
  {
    path: '/nation',
    name: 'nation',
    meta: { title: '四川民族概况' },
    component: () => import('../views/nation')
  },
  {
    path: '/village',
    name: 'village',
    meta: { title: '特色村寨数字化平台' },
    component: () => import('../views/village')
  },
  {
    path: '/search',
    name: 'search',
    meta: { title: '特色村寨数字化平台' },
    component: () => import('../views/search/search')
  },
  {
    path: '/news',
    name: 'news',
    meta: { title: '资讯速递' },
    component: () => import('../views/news/news')
  },
  {
    path: '/culture',
    name: 'culture',
    meta: { title: '非遗文化展示' },
    component: () => import('../views/culture/index')
  },
  {
    path: '/activity',
    name: 'activity',
    meta: { title: '民族手工艺在四川' },
    component: () => import('../views/activity')
  },
  {
    path: '/detail',
    name: 'allDetail',
    meta: { title: '详情页面' },
    component: () => import('../views/details/allDetail')
  },
  {
    path: '/farmProducts',
    name: 'farmProducts',
    meta: { title: '农牧产品页面' },
    component: () => import('../views/farmProducts/farmProducts')
  },
  {
    path: '/dataVisible',
    name: 'dataVisible',
    meta: { title: '数据统计', noLayout: true },
    component: () => import('../views/dataVisible/dataVisible')
  },
  {
    path: '/statement',
    name: 'statement',
    meta: { title: '免责声明' },
    component: () => import('../views/statement/statement')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log(to)
  if (
    to.path === '/nation' ||
    to.path === '/activity' ||
    to.path === '/detail' ||
    to.path === '/feature' ||
    to.path === '/culture' ||
    to.path === '/farmProducts' ||
    to.path === '/search'
  ) {
    document.body.scrollTop = document.documentElement.scrollTop = 750
  }
  next()
})

export default router
