<!-- 组件说明 -->
<template>
  <div class="footer_wrapper">
    <div class="footer_container">
      <div class="footer_logo">
        <img src="@/assets/img/footer_img_logo.png" alt="" />
      </div>
      <div class="footer_text">
        <div class="text_top">
          <div class="list">
            <div class="title">联系我们：</div>
            <div class="dec">四川省成都市金牛区黄金路196号</div>
            <div class="dec">scmzw@qq.com</div>
          </div>
          <div class="list">
            <div class="title">友情链接：</div>
            <div class="dec" @click="goWeb(1)">国家民族事务委员会</div>
            <div class="dec" @click="goWeb(2)">四川省人民政府</div>
            <div class="dec" @click="goWeb(3)">四川民族宗教事务委员会</div>
          </div>
        </div>
      </div>
      <div class="img_link">
        <div class="img_content">
          <img src="@/assets/img/footer_img_gzh.jpg" alt="" />
          <span>官方微信公众号</span>
        </div>
        <div class="img_content">
          <img src="@/assets/img/footer_img_xcx.png" alt="" />
          <span>官方微信小程序</span>
        </div>
      </div>
    </div>
    <div class="text_bottom">
      Copyright @ 2021成都拓坊商务咨询有限公司 All Rights Reserved |
      备案号：蜀ICP备20000041号 |
      <span style="cursor: pointer" @click="goTop">免责声明</span>
    </div>
    <div class="text_bottom latest">累计访问量：{{ visit_count }}</div>
  </div>
</template>

<script>
//import x from ''
export default {
  components: {},
  data() {
    return {
      visit_count: 0
    }
  },
  computed: {},
  created() {
    this.addVisit()
  },
  watch: {
    $route(to, from) {
      this.addVisit()
    }
  },
  methods: {
    goWeb(val) {
      // window.open('http://www.scmzbl.com/index.php?v=show&cid=421&id=497')
      if (val === 1) {
        window.open('https://www.neac.gov.cn/')
      }
      if (val === 2) {
        window.open('http://www.sc.gov.cn/')
      }
      if (val === 3) {
        window.open('http://mzw.sc.gov.cn/')
      }
    },
    goTop() {
      this.$router.push({ path: '/statement' })
    },
    addVisit() {
      this.$axios.post('/api/v2/var/visit_count').then((res) => {
        this.visit_count = res.data
      })
    }
  }
}
</script>

<style lang='scss' scoped>
//@import url()
.text_bottom {
  font-size: 0.24rem;
  color: #666;
  font-family: 'SimHei';
  display: flex;
  margin-bottom: 0.2rem;
  justify-content: center;
  align-items: center;
  &.latest {
    margin-bottom: 1rem;
  }
}
.footer_container {
  width: 100%;
  // height: 4.8rem;
  background: #fff;
  display: flex;
  .footer_logo {
    width: 14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: calc(100% - 3rem);
    }
  }
  .footer_text {
    width: calc(100% - 11rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.7rem 0 0.5rem 0;
    .text_top {
      display: flex;
      justify-content: space-between;
      .list {
        width: 50%;
        .title {
          font-size: 0.4rem;
          color: #333;
          font-family: 'MicrosoftYaHei';
        }
        .dec {
          font-size: 0.28rem;
          color: #333;
          font-family: 'SimHei';
          margin-top: 0.3rem;
          cursor: pointer;
        }
      }
    }
  }
  .img_link {
    width: 10rem;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .img_content {
      height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 80%;
      }
      span {
        margin-top: 0.2rem;
        font-size: 0.24rem;
        font-family: 'SimHei';
        color: #333333;
      }
    }
  }
}
</style>
<style lang="scss">
@media screen and (max-width: 600px) {
  .footer_logo {
    width: 30%;
  }
  .footer_logo img {
    width: 45%;
  }
  .footer_text {
    width: 40%;
  }
  .footer_text .title {
    font-size: 0.2rem;
  }
  .footer_text .dec {
    font-size: 0.12rem;
  }
  .footer_text .text_bottom {
    font-size: 0.12rem;
  }
  .img_link {
    width: 30%;
    padding: 0;
  }
  .img_link .img_content span {
    font-size: 0.12rem;
  }
}
</style>
