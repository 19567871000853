<template>
  <div id="app">
    <div class="router_container" v-if="$route.meta.noLayout">
      <router-view />
    </div>
    <div class="router_container" v-else>
      <Navigation />
      <div class="app_container">
        <div class="main_container">
          <router-view />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import { judIE } from '@/utils/tool'
import storeMixin from '@/components/Mixins/storeMixin'
import screenRatioMixin from '@/components/Mixins/screenRatioMixin'
import Navigation from '@/views/layout/navigation'
import Footer from '@/views/layout/footer'
export default {
  name: 'app',
  mixins: [storeMixin, screenRatioMixin],
  components: {
    Navigation,
    Footer
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.checkIE()
      this.open()
      window.addEventListener('scroll', this.onScroll)
    },
    // IE下引导用户
    checkIE() {
      if (judIE()) {
        this.$Notice.info({
          title: '推荐使用谷歌chrome浏览器',
          desc: '推荐您使用谷歌chrome浏览器访问，以获取更好的使用体验',
          duration: 15
        })
      }
    },
    open() {
      if (this.wxPic != null) {
        return
      }
      this.wxPic = this.$notify({
        title: '官方微信公众号',
        dangerouslyUseHTMLString: true,
        duration: 0,
        position: 'bottom-right',
        message:
          '<img src="' + this.getImg() + '"  height=117 width=117 alt="">',
        customClass: 'notify_class',
        offset: 110,
        showClose: false
        // message: '<div style= "height:50px;weight:45px"><img src="@/assets/img/footer_img_gzh.jpg" height=50 width=50 alt=""></div>'
      })
    },
    onScroll() {
      const el = document.documentElement
      if (
        el.scrollTop + window.innerHeight >
        document.body.clientHeight - 100
      ) {
        if (this.wxPic == null) {
          return
        }
        this.wxPic.close()
        this.wxPic = null
      } else {
        this.open()
      }
    },
    getImg() {
      return require('@/assets/img/footer_img_gzh.jpg')
    }
  }
}
</script>

<style lang="scss">
.notify_class {
  width: 3.5em !important;
}
.router_container {
  height: 100%;
}
.app_container {
  position: relative;
  background: #e4e4e4;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  .main_container {
    background: #f4f4f4;
    border-radius: 0.3rem;
    box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.12);
    top: -1rem;
    // width: calc(100% - 4rem);
    width: 76%;
    // max-width: 21rem;
    // padding: 0 1rem;
    padding: 0 10%;
    overflow: hidden;
  }
}
@media screen and (max-width: 1600px) {
  .app_container .main_container {
    width: 90%;
    padding: 0 5%;
  }
}
@media screen and (max-width: 600px) {
  .feature_container .title {
    font-size: 0.4rem !important;
    margin-bottom: 0.4rem !important;
  }
  .el-backtop {
    width: 0.5rem !important;
    height: 1rem !important;
  }
}
</style>
